//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import i18n from '@/vueI18n';

export default {
  name: 'app-exporter-form-scan-print',

  
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
    }),
    language(){
      return i18n.locale
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
