//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { ExporterModel } from '@/modules/exporter/exporter-model';
// import { ExporterPermissions } from '@/modules/exporter/exporter-permissions';
import i18n from '@/vueI18n';
import moment from 'moment';
import QrcodeVue from 'qrcode.vue'

const { fields } = ExporterModel;

export default {
  name: 'app-exporter-form-scan-table',
  props: ['rows'],
  components: {
    QrcodeVue,
  },

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      columns: [
        {
          name: 'name',
          field: 'itemDetails',
          label: 'entities.importer.fields.itemName',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
        },
        {
          name: 'itemType',
          field: 'itemType',
          label: 'entities.importer.fields.itemType',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
        },
        {
          name: 'serialNumber',
          field: 'serialNumber',
          label: 'entities.importer.fields.serialNumber',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
        },
        {
          name: 'qrCode',
          field: 'qrCode',
          label: 'entities.importer.fields.qrCode',
          align: 'center',
          required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center',
        },
      ],
      dialogVisible: false,
      dialogType: '',
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
    };
  },
  computed: {
    ...mapGetters({
      // rows: 'exporter/list/rows',
      // count: 'exporter/list/count',
      // loading: 'exporter/list/loading',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      // destroyLoading: 'exporter/destroy/loading',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    language(){
      return i18n.locale
    },

    // hasPermissionToEdit() {
    //   return new ExporterPermissions(this.currentUser).edit;
    // },

    // hasPermissionToDestroy() {
    //   return new ExporterPermissions(this.currentUser)
    //     .destroy;
    // },

    fields() {
      return fields;
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    }
  },

  methods: {
    ...mapActions({
      doChangeSort: 'exporter/list/doChangeSort',
      doChangePaginationCurrentPage: 'exporter/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'exporter/list/doChangePaginationPageSize',
      doMountTable: 'exporter/list/doMountTable',
      doDestroy: 'exporter/destroy/doDestroy',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      return ExporterModel.presenter(row, fieldName);
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : '';
    },
    presenterMap(row, fieldName) {
      const val = ExporterModel.presenter(row, fieldName);
      return val[this.language]
    },
    presenterDate(row, fieldName) {
      const val = ExporterModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : ''; 
    },
    presenterDay(row, fieldName) {
      return moment(ExporterModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = ExporterModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a"); 
    },

    doDelete(row) {
      this.$emit('doRemoveItem', row)
    },
    // closeDialog(){
    //   this.dialogVisible = false;
    //   this.dialogType = ''
    // },

    // openDialog(row, operation){
    //   this.selectedRow = row;
    //   this.selectedId = row.id;
    //   switch (operation) {
    //     case 'delete':
    //       this.dialogType = 'delete'
    //       break;
    //     case 'view':
    //       this.dialogType = 'view'
    //       break;
    //     default:
    //       break;
    //   }
    //   this.dialogVisible = true
    // },
    // async doDestroyWithConfirm(id) {
    //   try {
    //     await this.doDestroy(id);
    //     this.closeDialog();
    //   } catch (error) {
    //     // no
    //   }
    // },
  },
};
