//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import QrcodeVue from 'qrcode.vue'
// import i18n from '@/vueI18n';
import { routerAsync } from '@/app-module';

export default {
  name: "app-print-pdf-page",
  props: ['data', 'sellerName', 'sellerPhone'],
 components: {
    QrcodeVue,
  },
  data() {
    return {

      show: true,
      // data: [
      //   {
      //     answerNumber: "0000112378236",
      //     QRCode: 1233224,
      //     productName: "jsdkh",
      //   },
      //   {
      //     answerNumber: "0000112378236",
      //     QRCode: 1233224,
      //     productName: "jsdkh",
      //   },
      // ],
      columns: [
        // {
        //   name: "رمز الاستجابة السريعة",
        //   align: "center",
        //   label: "رمز الاستجابة السريعة",
        //   field: "answerNumber",
        // },
        // {
        //   name: "الرقم التسلسلى",
        //   align: "center",
        //   label: "الرقم التسلسلى",
        //   field: "QRCode",
        // },
        // {
        //   name: "اسم المنتج",
        //   align: "center",
        //   label: "اسم المنتج",
        //   field: "productName",
        // },
        {
          name: 'qrCode',
          field: 'qrCode',
          label: 'entities.importer.fields.qrCode',
          align: 'center',
        },
        {
          name: 'serialNumber',
          field: 'serialNumber',
          label: 'entities.importer.fields.serialNumber',
          align: 'center',
        },
         {
          name: 'name',
          field: 'itemDetails',
          label: 'entities.importer.fields.itemName',
          align: 'center',
        },
      ],
    };
  },
  methods: {
    print() {
       console.log("heyyyy");
      this.$htmlToPaper("printMe");
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    doCancel() {
        routerAsync().push('/export');
    },
  },
};
